import {
  GET_AKUNOLT,
  ADD_AKUNOLT,
  DELETE_AKUNOLT,
  EDIT_AKUNOLT,
  UPDATE_AKUNOLT,
} from '../../pages/actions/oltAction';

const akunolt = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_AKUNOLT:
      return {
        ...state,
        getAkunoltResult: action.payload.data,
        getAkunoltLoading: action.payload.loading,
        getAkunoltError: action.payload.errorMessage,
      };
    case ADD_AKUNOLT:
      return {
        ...state,
        addAkunoltResult: action.payload.data,
        addAkunoltLoading: action.payload.loading,
        addAkunoltError: action.payload.errorMessage,
      };
    case DELETE_AKUNOLT:
      return {
        ...state,
        deleteAkunoltResult: action.payload.data,
        deleteAkunoltLoading: action.payload.loading,
        deleteAkunoltError: action.payload.errorMessage,
      };
    case EDIT_AKUNOLT:
      return {
        ...state,
        editAkunoltResult: action.payload.data,
      };
    case UPDATE_AKUNOLT:
      return {
        ...state,
        updateAkunoltResult: action.payload.data,
        updateAkunoltLoading: action.payload.loading,
        updateAkunoltError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default akunolt;
