import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL_tagihanpelanggan } from '../constants';
import { API_URL_edeltagihanpelanggan } from '../constants';

export const GET_TAGIHAN_PELANGGAN = 'GET_TAGIHAN_PELANGGAN';
export const EDIT_TAGIHAN_PELANGGAN = 'EDIT_TAGIHAN_PELANGGAN';
export const UPDATE_TAGIHAN_PELANGGAN = 'UPDATE_TAGIHAN_PELANGGAN';

export const getTagihanPelanggan = dispatch => {
  dispatch({
    type: GET_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_tagihanpelanggan,
  })
    .then(response => {
      dispatch({
        type: GET_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editTagihanPelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_TAGIHAN_PELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updateTagihanPelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_TAGIHAN_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edeltagihanpelanggan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: UPDATE_TAGIHAN_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_TAGIHAN_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
