import {
  GET_FTTX,
  ADD_FTTX,
  DELETE_FTTX,
} from '../../pages/actions/fttxAction ';

const fttx = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_FTTX:
      return {
        ...state,
        getFttxResult: action.payload.data,
        getFttxLoading: action.payload.loading,
        getFttxError: action.payload.errorMessage,
      };
    case ADD_FTTX:
      return {
        ...state,
        addFttxResult: action.payload.data,
        addFttxLoading: action.payload.loading,
        addFttxError: action.payload.errorMessage,
      };
    case DELETE_FTTX:
      return {
        ...state,
        deleteFttxResult: action.payload.data,
        deleteFttxLoading: action.payload.loading,
        deleteFttxError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default fttx;
