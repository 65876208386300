import axios from 'axios';
import { API_URL_testkoneksirouter } from '../constants';

export const TEST_AKUN_ROUTER = 'TEST_AKUN_ROUTER';

export const tesAkunRouter = (dispatch, data) => {
  dispatch({
    type: TEST_AKUN_ROUTER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_testkoneksirouter,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      dispatch({
        type: TEST_AKUN_ROUTER,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: TEST_AKUN_ROUTER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
