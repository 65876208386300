import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './login.css';
import './main.css';
import './util.css';
import './fonts/font-awesome-4.7.0/css/font-awesome.css';
import './fonts/iconic/css/material-design-iconic-font.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { ThreeDots } from 'react-loader-spinner';

const Login = ({ loginUser, history }) => {
  const { loginuserResult, loginuserLoading, loginuserError } = useSelector(
    state => state.LoginReducer,
  );

  const dispatch = useDispatch();
  const [state, setState] = useState({
    username: '',
    password: '',
  });
  const theme = localStorage.getItem("theme");


  const handleChange = event => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (loginuserResult) {
      window.location.href = '/registrasi';
    }
  }, [loginuserResult]);

  const login = async event => {
    event.preventDefault();
    const { username, password } = state;

    await loginUser(username, password);
  };
  return (
    <div>
      <div className="themeappscyan">
        <ul className="bg-bubbles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div
          className="container-login100"
          // style={{ backgroundImage: 'url("images/bg-01.jpg")' }}
        >
          <div className="wrap-login100 p-l-25 p-r-25 p-t-65 p-b-54">
            <form className="login100-form validate-form" onSubmit={login}>
              <span className="login100-form-title p-b-30">Silahkan Login</span>
              {loginuserError ? (
                <p className="text-danger mb-3 text-center">
                  Username Atau Password Salah!
                </p>
              ) : null}
              <div
                className="wrap-input100 validate-input m-b-23"
                data-validate="Username is reauired"
              >
                <span className="label-input100">Username</span>
                <input
                  className="input100"
                  type="text"
                  name="username"
                  placeholder="Masukkan Username"
                  autoFocus
                  required
                  value={state.username}
                  onChange={handleChange}
                />
                <span className="focus-input100" data-symbol="" />
              </div>
              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <span className="label-input100">Password</span>
                <input
                  className="input100"
                  type="password"
                  name="password"
                  placeholder="Masukkan Password"
                  required
                  value={state.password}
                  onChange={handleChange}
                />
                <span className="focus-input100" data-symbol="" />
              </div>
              <div className="text-right p-t-8 p-b-31"></div>
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className={theme===null ?"themeappscyan":theme }/>
                  {loginuserLoading ? (
                    <button className={theme===null ?"themeappscyan":theme } style={{width:"100%",display:"flex",justifyContent:"center"}} disabled>
                      <ThreeDots
                        height="45"
                        width="45"
                        radius="9"
                        color="#FFFFFF"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button type="submit" className={theme===null ?"themeappscyan":theme } style={{width:"100%", color:"#ffff",padding:10,fontWeight:"bold"}}>
                      Login
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  loginUser,
};

export default connect(null, mapDispatchToProps)(Login);
// export default Login;
