import {
  GET_WILAYAH,
  ADD_WILAYAH,
  DELETE_WILAYAH,
} from '../../pages/actions/wilayahAction';

const wilayah = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_WILAYAH:
      return {
        ...state,
        getWilayahResult: action.payload.data,
        getWilayahLoading: action.payload.loading,
        getWilayahError: action.payload.errorMessage,
      };
    case ADD_WILAYAH:
      return {
        ...state,
        addWilayahResult: action.payload.data,
        addWilayahLoading: action.payload.loading,
        addWilayahError: action.payload.errorMessage,
      };
    case DELETE_WILAYAH:
      return {
        ...state,
        deleteWilayahResult: action.payload.data,
        deleteWilayahLoading: action.payload.loading,
        deleteWilayahError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default wilayah;
