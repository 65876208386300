import {
  GET_ROUTER,
  ADD_ROUTER,
  DELETE_ROUTER,
  EDIT_ROUTER,
  UPDATE_ROUTER,
} from '../../pages/actions/routerAction';

const router = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_ROUTER:
      return {
        ...state,
        getRouterResult: action.payload.data,
        getRouterLoading: action.payload.loading,
        getRouterError: action.payload.errorMessage,
      };
    case ADD_ROUTER:
      return {
        ...state,
        addRouterResult: action.payload.data,
        addRouterLoading: action.payload.loading,
        addRouterError: action.payload.errorMessage,
      };
    case DELETE_ROUTER:
      return {
        ...state,
        deleteRouterResult: action.payload.data,
        deleteRouterLoading: action.payload.loading,
        deleteRouterError: action.payload.errorMessage,
      };
    case EDIT_ROUTER:
      return {
        ...state,
        editRouterResult: action.payload.data,
      };
    case UPDATE_ROUTER:
      return {
        ...state,
        updateRouterResult: action.payload.data,
        updateRouterLoading: action.payload.loading,
        updateRouterError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default router;
