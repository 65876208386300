import {
  TEST_AKUN_OLT,
  // ADD_USER,
  // DELETE_USER,
  // EDIT_USER,
  // UPDATE_USER,
} from '../../pages/actions/tesakunOltAction';

const tesakunolt = (state, action) => {
  const { type } = action;
  switch (type) {
    case TEST_AKUN_OLT:
      return {
        ...state,
        tesakunoltResult: action.payload.data,
        tesakunoltLoading: action.payload.loading,
        tesakunoltError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default tesakunolt;
