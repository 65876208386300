import axios from 'axios';
import Swal from 'sweetalert2';
import {
  API_URL_getusers,
  API_URL_addusers,
  API_URL_edeluser,
} from '../constants';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const getUser = (dispatch, data) => {
  dispatch({
    type: GET_USER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: API_URL_getusers + data.param,
  })
    .then(response => {
      dispatch({
        type: GET_USER,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_USER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addUser = (dispatch, data) => {
  dispatch({
    type: ADD_USER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_addusers,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      Swal.fire('Good job!', response.data.message, 'success');
      dispatch({
        type: ADD_USER,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
      dispatch({
        type: ADD_USER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
export const editUseraction = (dispatch, data) => {
  dispatch({
    type: EDIT_USER,
    payload: {
      data: data,
    },
  });
};

export const updateUser = (dispatch, data) => {
  dispatch({
    type: UPDATE_USER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_edeluser + data.pk,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      Swal.fire('Good job!', response.data.messages, 'success');
      dispatch({
        type: UPDATE_USER,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_USER,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteUser = (dispatch, pk) => {
  dispatch({
    type: DELETE_USER,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: API_URL_edeluser + pk,
        timeout: 120000,
      })
        .then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: 'success',
            title: response.data.messages,
          });
          dispatch({
            type: DELETE_USER,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_USER,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
