import axios from 'axios';
import Swal from 'sweetalert2';

export const GET_WILAYAH = 'GET_WILAYAH';
export const ADD_WILAYAH = 'ADD_WILAYAH';
export const DELETE_WILAYAH = 'DELETE_WILAYAH';

export const getWilayah = dispatch => {
  dispatch({
    type: GET_WILAYAH,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'GET',
    url: 'http://localhost:8000/api/getpengembanganwilayahfttx/',
  })
    .then(response => {
      dispatch({
        type: GET_WILAYAH,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_WILAYAH,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addWilayah = (dispatch, data) => {
  dispatch({
    type: ADD_WILAYAH,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: 'http://localhost:8000/api/createwilayahfttx/',
    timeout: 120000,
    data: data,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire('Good job!', response.data.messages, 'success');
        dispatch({
          type: ADD_WILAYAH,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: ADD_WILAYAH,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteWilayah = (dispatch, pk) => {
  dispatch({
    type: DELETE_WILAYAH,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#6a82fb',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(result => {
    if (result.isConfirmed) {
      axios({
        method: 'DELETE',
        url: 'http://localhost:8000/api/edelwilayahfttx/' + pk,
        timeout: 120000,
      })
        .then(response => {
          Swal.fire('Deleted!', response.data.messages, 'success');
          dispatch({
            type: DELETE_WILAYAH,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          });
          dispatch({
            type: DELETE_WILAYAH,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
