import { GET_REGPELANGGAN, ADD_REGPELANGGAN, DELETE_REGPELANGGAN, EDIT_REGPELANGGAN, UPDATE_REGPELANGGAN, UPLOAD_DATA_REGPELANGGAN } from "../../pages/actions/regpelangganAction";

const regpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_REGPELANGGAN:
      return {
        ...state,
        getRegpelangganResult: action.payload.data,
        getRegpelangganLoading: action.payload.loading,
        getRegpelangganError: action.payload.errorMessage,
      };
    case ADD_REGPELANGGAN:
      return {
        ...state,
        addRegpelangganResult: action.payload.data,
        addRegpelangganLoading: action.payload.loading,
        addRegpelangganError: action.payload.errorMessage,
      };
    case DELETE_REGPELANGGAN:
      return {
        ...state,
        deleteRegpelangganResult: action.payload.data,
        deleteRegpelangganLoading: action.payload.loading,
        deleteRegpelangganError: action.payload.errorMessage,
      };
    case EDIT_REGPELANGGAN:
      return {
        ...state,
        editRegpelangganResult: action.payload.data,
      };
    case UPDATE_REGPELANGGAN:
      return {
        ...state,
        updateRegpelangganResult: action.payload.data,
        updateRegpelangganLoading: action.payload.loading,
        updateRegpelangganError: action.payload.errorMessage,
      };
    case UPLOAD_DATA_REGPELANGGAN:
      return {
        ...state,
        uploaddataRegpelangganResult: action.payload.data,
        uploaddataRegpelangganLoading: action.payload.loading,
        uploaddataRegpelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default regpelanggan;
