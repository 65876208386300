const localhost = `https://api.erpkeuangan.jpdn.net.id`; 
// const localhostconfig = `https://api.teknis.qnn.co.id`;
const localhostconfig = `https://api.erpkeuangan.jpdn.net.id`;
const apiURL = ``;
const endpoint = `${localhost}${apiURL}`;
const endpointconfig = `${localhostconfig}${apiURL}`;

export const API_URL_exportdataoutofarea = `${endpoint}/exportdataoutofarea/`;
export const API_URL_exportdatapelanggan = `${endpoint}/exportdatapelanggan/`;

//DASHBOARD
export const API_URL_datapelangganbulanlalu = `${endpoint}/datapelangganbulanlalu/`;
export const API_URL_datapelangganbulanini = `${endpoint}/datapelangganbulanini/`;
export const API_URL_datapelangganbulanlalusemua = `${endpoint}/datapelangganbulanlalusemua/`;
export const API_URL_datapelangganbulaninisemua = `${endpoint}/datapelangganbulaninisemua/`;
export const API_URL_datapelangganmingguini = `${endpoint}/datapelangganmingguini/`;
export const API_URL_datapelangganminggulalu = `${endpoint}/datapelangganminggulalu/`;
export const API_URL_datapelangganhariini = `${endpoint}/datapelangganhariini/`;
export const API_URL_antrianinstalasipelanggan = `${endpoint}/antrianinstalasipelanggan/`;
export const API_URL_media = `${endpoint}`;

// NEW API ROUTE
export const API_URL_loginuser = `${endpoint}/login/`;

export const API_URL_getakunolt = `${endpoint}/getakunolt/`;

export const API_URL_edelakunolt = `${endpoint}/edelakunolt/`;

export const API_URL_getakunrouter = `${endpoint}/getakunrouter/`;

export const API_URL_edelakunrouter = `${endpoint}/edelakunrouter/`;

export const API_URL_getprovinsi = `${endpoint}/getprovinsi/`;
export const API_URL_getkabkot = `${endpoint}/getkabkot/`;

export const API_URL_getoltnode = `${endpoint}/nodeolt/`;
export const API_URL_getoltoption = `${endpoint}/getoltnode/`;
export const API_URL_getoltnodedetail = `${endpoint}/getoltnodedetail/`;
export const API_URL_createoltnode = `${endpoint}/createoltnode/`;
export const API_URL_edeloltnode = `${endpoint}/edeloltnode/`;

export const API_URL_regpelanggan = `${endpoint}/regpelanggan/`;
export const API_URL_getregpelanggan = `${endpoint}/getregpelanggan/`;
export const API_URL_edelregpelanggan = `${endpoint}/edelregpelanggan/`;

export const API_URL_getoltregpelanggan = `${endpoint}/getoltregpelanggan/`;
export const API_URL_createoltregpelanggan = `${endpoint}/createoltregpelanggan/`;
export const API_URL_edeloltregpelanggan = `${endpoint}/edeloltregpelanggan/`;

export const API_URL_ambildataconfig = `${endpoint}/ambildataconfig/`;
export const API_URL_setuppelanggan = `${endpointconfig}/setuppelanggan/`;

export const API_URL_spesifikpelanggan = `${endpoint}/spesifikpelanggan/`;
export const API_URL_detektiketgangguan = `${endpoint}/detektiketgangguan/`;
export const API_URL_detilteknispelanggan = `${endpoint}/detailteknispelanggan/`;
export const API_URL_infomodempelanggan = `${endpoint}/infomodempelanggan/`;
export const API_URL_rebootmodem = `${endpoint}/rebootmodem/`;
export const API_URL_restorefactory = `${endpoint}/restorefactory/`;

export const API_URL_datapelanggan = `${endpoint}/pelanggan/`;
export const API_URL_createpelanggan = `${endpoint}/createpelanggan/`;
export const API_URL_datapelanggandetail = `${endpoint}/datapelanggandetail/`;
export const API_URL_tagihanpelanggan = `${endpoint}/tagihanpelanggan/`;
export const API_URL_edeltagihanpelanggan = `${endpoint}/edeltagihanpelanggan/`;

export const API_URL_statuspelanggan = `${endpoint}/getstatuspelanggan/`;
export const API_URL_perusahaan = `${endpoint}/getperusahaan/`;

export const API_URL_getusers = `${endpoint}/getusers/`;
export const API_URL_edeluser = `${endpoint}/edeluser/`;
export const API_URL_addusers = `${endpoint}/register/`;

export const API_URL_createdatateknis = `${endpoint}/createdatateknis/`;

export const API_URL_edeldatateknis = `${endpoint}/edeldatateknis/`;

// ###############################################
export const API_URL_getpengembanganwilayahfttx = `${endpoint}/getpengembanganwilayahfttx/`;
export const API_URL_createwilayahfttx = `${endpoint}/createwilayahfttx/`;
export const API_URL_edelwilayahfttx = `${endpoint}/edelwilayahfttx/`;

export const API_URL_getlistfttx = `${endpoint}/getnodefttx/`;

// ################

export const API_URL_setuppelanggan1 = `${endpoint}/setuppelanggan1/`;
export const API_URL_infogantimodem = `${endpoint}/infogantimodem/`;
export const API_URL_cekpasswifi = `${endpoint}/cekpasswifi/`;
export const API_URL_bandwidthupgrade = `${endpoint}/bandwidthupgrade/`;
export const API_URL_gantipassword = `${endpoint}/gantipassword/`;
export const API_URL_cekInfodialup = `${endpoint}/cekInfodialup/`;
export const API_URL_gantimodem = `${endpoint}/gantimodem/`;

export const API_URL_detektroti = `${endpoint}/detektroti/`;
export const API_URL_datagangguanpelanggan = `${endpoint}/datagangguanpelanggan/`;
export const API_URL_tiketgangguan = `${endpoint}/tiketgangguan/`;
export const API_URL_edeldatagangguan = `${endpoint}/edeldatagangguan/`;
export const API_URL_createdatagangguan = `${endpoint}/createdatagangguan/`;
export const API_URL_closedatagangguan = `${endpoint}/closedatagangguan/`;

export const API_URL_exportpelangganperolt = `${endpoint}/exportpelangganperolt/`;

export const API_URL_Settingswifi = `${endpoint}/settingswifi/`;
export const API_URL_ambildatawifi = `${endpoint}/ambildatawifi/`;

export const API_URL_testkoneksirouter = `${endpoint}/testkoneksirouter/`;
export const API_URL_createakunrouter = `${endpoint}/createakunrouter/`;
export const API_URL_createakunolt = `${endpoint}/createakunolt/`;
export const API_URL_testkoneksiolt = `${endpoint}/testkoneksiolt/`;

export const API_URL_getwilayah = `${endpoint}/getwilayah/`;

export const API_URL_editregpelanggan = `${endpoint}/editregpelanggan/`;
export const API_URL_getidkotakab = `${endpoint}/getidkotakab/`;
export const API_URL_getprofileforselect = `${endpoint}/getprofileforselect/`;

export const API_URL_getprofilebandwidth = `${endpoint}/getprofilebandwidth/`;
export const API_URL_getprofilevlan = `${endpoint}/getprofilevlan/`;
export const API_URL_getprofilebwdb = `${endpoint}/getprofilebwdb/`;
export const API_URL_saveprofiledownload = `${endpoint}/saveprofiledownload/`;
export const API_URL_delprofiledownload = `${endpoint}/delprofiledownload/`;

export const API_URL_createnodefttx = `${endpoint}/createnodefttx/`;
export const API_URL_edelnodefttx = `${endpoint}/edelnodefttx/`;

export const API_URL_saveprofileupload = `${endpoint}/saveprofileupload/`;
export const API_URL_delprofileupload = `${endpoint}/delprofileupload/`;

export const API_URL_saveprofilevlanoam = `${endpoint}/saveprofilevlanoam/`;
export const API_URL_delprofilevlanoam = `${endpoint}/delprofilevlanoam/`;

export const API_URL_saveprofilevlanservis = `${endpoint}/saveprofilevlanservis/`;
export const API_URL_delprofilevlanservis = `${endpoint}/delprofilevlanservis/`;

export const API_URL_configpelanggan = `${endpoint}/configpelanggan/`;
export const API_URL_getpaketinternet = `${endpoint}/getpaketinternet/`;

export const API_URL_tesmodem = `${endpoint}/tesmodem/`;
export const API_URL_dataterconfig = `${endpoint}/dataterconfig/`;
export const API_URL_deletemodem = `${endpoint}/deletemodem/`;
export const API_URL_residumodem = `${endpoint}/residumodem/`;
export const API_URL_configmodem = `${endpoint}/configmodem/`;
export const API_URL_restoremodem = `${endpoint}/restoremodem/`;
export const API_URL_createbarangmasuk = `${endpoint}/createbarangmasuk/`;
export const API_URL_edelbarangmasuk = `${endpoint}/edelbarangmasuk/`;
export const API_URL_getbarangmasuk = `${endpoint}/getbarangmasuk/`;
export const API_URL_getdetailperangkat = `${endpoint}/getdetailperangkat/`;

export const API_URL_getsales = `${endpoint}/getsales/`;