import axios from "axios";
import Swal from "sweetalert2";
import { API_URL_getregpelanggan } from "../constants";
import { API_URL_regpelanggan } from "../constants";
import { API_URL_edelregpelanggan } from "../constants";

export const GET_REGPELANGGAN = "GET_REGPELANGGAN";
export const ADD_REGPELANGGAN = "ADD_REGPELANGGAN";
export const EDIT_REGPELANGGAN = "EDIT_REGPELANGGAN";
export const UPDATE_REGPELANGGAN = "UPDATE_REGPELANGGAN";
export const DELETE_REGPELANGGAN = "DELETE_REGPELANGGAN";
export const UPLOAD_DATA_REGPELANGGAN = "UPLOAD_DATA_REGPELANGGAN";

export const getRegpelanggan = (dispatch, data) => {
  dispatch({
    type: GET_REGPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_regpelanggan + data.status + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_REGPELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_REGPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addRegpelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_REGPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_getregpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_REGPELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_REGPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editRegpelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_REGPELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updateRegpelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_REGPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edelregpelanggan + data.pk,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_REGPELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_REGPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const uploaddataRegpelanggan = (dispatch, data) => {
  dispatch({
    type: UPLOAD_DATA_REGPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_edelregpelanggan + data.pk,
    timeout: 120000,
    data: data.formData,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPLOAD_DATA_REGPELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPLOAD_DATA_REGPELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const deleteRegpelanggan = (dispatch, pk) => {
  dispatch({
    type: DELETE_REGPELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#6a82fb",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "DELETE",
        url: API_URL_edelregpelanggan + pk,
        timeout: 120000,
      })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: response.data.messages,
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch({
            type: DELETE_REGPELANGGAN,
            payload: {
              loading: false,
              data: response.data,
              errorMessage: false,
            },
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
          });
          dispatch({
            type: DELETE_REGPELANGGAN,
            payload: {
              loading: false,
              data: false,
              errorMessage: error.message,
            },
          });
        });
    }
  });
};
