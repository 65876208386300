import wilayahReducer from "./wilayah";
import fttxReducer from "./fttx";
import oltReducer from "./nodeolt";
import routerReducer from "./router";
import akunoltReducer from "./akunolt";
import regpelangganReducer from "./regpelanggan";
import oltregpelangganReducer from "./oltregpelanggan";
import setuppelangganReducer from "./setuppelanggan";
import pelangganReducer from "./pelanggan";
import userReducer from "./users";
import tagihanpelangganReducer from "./tagihanpelanggan";
import tesakunrouterReducer from "./tesakunrouter";
import tesakunoltReducer from "./tesakunolt";
import datateknisReducer from "./datateknis";
import rebootmodemReducer from "./rebootmodempelanggan";
import barangmasukReducer from "./barangmasuk";
import tesmodemReducer from "./tesmodem";
import gangguanpelangganReducer from './gangguanpelanggan';

const initialState = {
  getWilayahResult: false,
  getWilayahLoading: false,
  getWilayahError: false,
  addWilayahResult: false,
  addWilayahLoading: false,
  addWilayahError: false,
  deleteWilayahResult: false,
  deleteWilayahLoading: false,
  deleteWilayahError: false,

  getFttxResult: false,
  getFttxLoading: false,
  getFttxError: false,
  addFttxResult: false,
  addFttxLoading: false,
  addFttxError: false,
  deleteFttxResult: false,
  deleteFttxLoading: false,
  deleteFttxError: false,

  getOltResult: false,
  getOltLoading: false,
  getOltError: false,
  getOltDetailResult: false,
  getOltDetailLoading: false,
  getOltDetailError: false,
  addOltResult: false,
  addOltLoading: false,
  addOltError: false,
  deleteOltResult: false,
  deleteOltLoading: false,
  deleteOltError: false,
  editOltResult: false,
  updateOltResult: false,
  updateOltLoading: false,
  updateOltError: false,

  getRouterResult: false,
  getRouterLoading: false,
  getRouterError: false,
  addRouterResult: false,
  addRouterLoading: false,
  addRouterError: false,
  deleteRouterResult: false,
  deleteRouterLoading: false,
  deleteRouterError: false,
  editRouterResult: false,
  updateRouterResult: false,
  updateRouterLoading: false,
  updateRouterError: false,

  getAkunoltResult: false,
  getAkunoltLoading: false,
  getAkunoltError: false,
  addAkunoltResult: false,
  addAkunoltLoading: false,
  addAkunoltError: false,
  deleteAkunoltResult: false,
  deleteAkunoltLoading: false,
  deleteAkunoltError: false,
  editAkunoltResult: false,
  updateAkunoltResult: false,
  updateAkunoltLoading: false,
  updateAkunoltError: false,

  getRegpelangganResult: false,
  getRegpelangganLoading: false,
  getRegpelangganError: false,
  addRegpelangganResult: false,
  addRegpelangganLoading: false,
  addRegpelangganError: false,
  deleteRegpelangganResult: false,
  deleteRegpelangganLoading: false,
  deleteRegpelangganError: false,
  editRegpelangganResult: false,
  updateRegpelangganResult: false,
  updateRegpelangganLoading: false,
  updateRegpelangganError: false,
  uploaddataRegpelangganResult: false,
  uploaddataRegpelangganLoading: false,
  uploaddataRegpelangganError: false,

  getOltRegpelangganResult: false,
  getOltRegpelangganLoading: false,
  getOltRegpelangganError: false,
  addOltRegpelangganResult: false,
  addOltRegpelangganLoading: false,
  addOltRegpelangganError: false,
  deleteOltRegpelangganResult: false,
  deleteOltRegpelangganLoading: false,
  deleteOltRegpelangganError: false,
  editOltRegpelangganResult: false,
  updateOltRegpelangganResult: false,
  updateOltRegpelangganLoading: false,
  updateOltRegpelangganError: false,

  setuppelangganResult: false,
  setuppelangganLoading: false,
  setuppelangganError: false,

  getpelangganResult: false,
  getpelangganLoading: false,
  getpelangganError: false,
  regpelangganselectedResult: 1,
  addpelangganResult: false,
  addpelangganLoading: false,
  addpelangganError: false,
  editpelangganResult: false,
  updatepelangganResult: false,
  updatepelangganLoading: false,
  updatepelangganError: false,

  getTagihanpelangganResult: false,
  getTagihanpelangganLoading: false,
  getTagihanpelangganError: false,
  editTagihanpelangganResult: false,
  updateTagihanpelangganResult: false,
  updateTagihanpelangganLoading: false,
  updateTagihanpelangganError: false,

  getUserResult: false,
  getUserLoading: false,
  getUserError: false,
  editUserResult: false,
  addUserResult: false,
  addUserLoading: false,
  addUserError: false,
  deleteUserResult: false,
  deleteUserLoading: false,
  deleteUserError: false,
  updateUserResult: false,
  updateUserLoading: false,
  updateUserError: false,

  tesakunrouterResult: false,
  tesakunrouterLoading: false,
  tesakunrouterError: false,

  tesakunoltResult: false,
  tesakunoltLoading: false,
  tesakunoltError: false,

  getdatateknisResult: false,
  getdatateknisLoading: false,
  getdatateknisError: false,
  adddatateknisResult: false,
  adddatateknisLoading: false,
  adddatateknisError: false,
  updatedatateknisResult: false,
  updatedatateknisLoading: false,
  updatedatateknisError: false,

  rebootbodemResult: false,
  rebootbodemLoading: false,
  rebootbodemError: false,

  getgangguanResult: false,
  getgangguanLoading: false,
  getgangguanError: false,
  gettiketgangguanResult: false,
  gettiketgangguanLoading: false,
  gettiketgangguanError: false,
  addgangguanResult: false,
  addgangguanLoading: false,
  addgangguanError: false,
  updategangguanResult: false,
  updategangguanLoading: false,
  updategangguanError: false,

  //barangmasuk
  getBarangmasukResult: false,
  getBarangmasukLoading: false,
  getBarangmasukError: false,
  addBarangmasukResult: false,
  addBarangmasukLoading: false,
  addBarangmasukError: false,
  deleteBarangmasukResult: false,
  deleteBarangmasukLoading: false,
  deleteBarangmasukError: false,
  editBarangmasukResult: false,
  updateBarangmasukResult: false,
  updateBarangmasukLoading: false,
  updateBarangmasukError: false,
  addBarangresiduResult: false,
  addBarangresiduLoading: false,
  addBarangresiduError: false,

  addConfigModemResult: false,
  addConfigModemLoading: false,
  addConfigModemError: false,
  deleteConfigModemResult: false,
  deleteConfigModemLoading: false,
  deleteConfigModemError: false,
};

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const appReducers = combineReducers({
  wilayahReducer,
  fttxReducer,
  oltReducer,
  routerReducer,
  akunoltReducer,
  regpelangganReducer,
  oltregpelangganReducer,
  setuppelangganReducer,
  pelangganReducer,
  userReducer,
  tagihanpelangganReducer,
  tesakunrouterReducer,
  tesakunoltReducer,
  datateknisReducer,
  rebootmodemReducer,
  barangmasukReducer,
  tesmodemReducer,
  gangguanpelangganReducer,
});

export { initialState, combineReducers, appReducers };
