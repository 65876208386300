import logo from "../../assets/img/logo/logo.jpg";
import sidebarBgImage from "../../assets/img/sidebar/sidebar-4.jpg";
import SourceLink from "../../components/SourceLink";
import React from "react";
import { FaWifi, FaThList, FaUsers, FaUserFriends, FaListUl, FaToolbox, FaListAlt } from "react-icons/fa";
import { MdDashboard, MdExtension, MdKeyboardArrowDown, MdRadioButtonChecked } from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "../../utils/bemnames";

const sidebarBackground = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const navItems = [
  { to: "/", name: "Dashboard", exact: true, Icon: MdDashboard },
  // { to: '/users', name: 'User', exact: false, Icon: FaUsers },
  // { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
  // { to: '/widgets', name: 'widgets', exact: false, Icon: MdWidgets },
];

const navConfigtool = [
  { to: "/commprov", name: "c & P", exact: false, Icon: MdRadioButtonChecked },
  {
    to: "/atnhuawei",
    name: "setup atn",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/setupmikrotik",
    name: "setting mikrotik",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/switchcisco",
    name: "switch cisco",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/oltzte",
    name: "c & p olt zte",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navFilemaster = [
  // { to: '/wilayah', name: 'wilayah', exact: false, Icon: MdRadioButtonChecked },
  // {
  //   to: '/listfttx',
  //   name: 'list fttx',
  //   exact: false,
  //   Icon: MdRadioButtonChecked,
  // },
  {
    to: "/nodeolt",
    name: "node olt",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/testscript",
    name: "test script",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navEquipmentfttx = [
  {
    to: "/tesmodem",
    name: "Tes Modem",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/comprovrouter",
    name: "c & P router",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/comprovolt",
    name: "c & p olt",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navPelanggan = [
  {
    to: "/registrasi",
    name: "registrasi",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/datapelanggan",
    name: "data pelanggan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/tiketgangguan",
    name: "tiket gangguan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navPartner = [
  {
    to: "/vendor",
    name: "vendor",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  { to: "/chanels", name: "chanels", exact: false, Icon: MdRadioButtonChecked },
  {
    to: "/freelence",
    name: "freelence",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

const navInventori = [
  {
    to: "/dataperangkat",
    name: "data perangkat",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/dataperalatan",
    name: "data peralatan",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/dataaset",
    name: "data aset",
    exact: false,
    Icon: MdRadioButtonChecked,
  },
];

// const navComponents = [
//   { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
//   {
//     to: '/button-groups',
//     name: 'button groups',
//     exact: false,
//     Icon: MdGroupWork,
//   },
//   { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
//   { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
//   {
//     to: '/dropdowns',
//     name: 'dropdowns',
//     exact: false,
//     Icon: MdArrowDropDownCircle,
//   },
//   { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
//   { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
//   { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
//   { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
// ];

// const navContents = [
//   { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
//   { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
// ];

// const pageContents = [
//   { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
//   {
//     to: '/login-modal',
//     name: 'login modal',
//     exact: false,
//     Icon: MdViewCarousel,
//   },
// ];

const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  state = {
    isOpenConfigtool: false,
    isOpenFilemaster: false,
    isOpenEquipmentfttx: false,
    isOpenPelanggan: false,
    isOpenPartner: false,
    isOpenInventori: false,
    // isOpenComponents: false,
    // isOpenContents: false,
    // isOpenPages: false,
  };

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];
      const prev = this.state["prevOpen"];
      return {
        [prev]: false,
        [`isOpen${name}`]: !isOpen,
        ["prevOpen"]: `isOpen${name}`,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background themeappscyan")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img src={logo} width="150" height="90" className="pr-2" alt="" />
              {/* <span className="text-white">
                ERP GPL <FaWifi />
              </span> */}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink id={`navItem-${name}-${index}`} className="" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Configtool")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaToolbox className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Config Tool</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenConfigtool ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenConfigtool}>
              {navConfigtool.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Filemaster")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaThList className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">File Master</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenFilemaster ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenFilemaster}>
              {navFilemaster.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Equipmentfttx")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <MdExtension className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Equipment Fttx</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenEquipmentfttx ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenEquipmentfttx}>
              {navEquipmentfttx.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Pelanggan")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaListAlt className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Pelanggan</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPelanggan ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPelanggan}>
              {navPelanggan.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Partner")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaUserFriends className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Partner</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPartner ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPartner}>
              {navPartner.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e("nav-item")} onClick={this.handleClick("Inventori")}>
              <BSNavLink className={bem.e("nav-item-collapse")}>
                <div className="d-flex">
                  <FaListUl className={bem.e("nav-item-icon")} />
                  <span className=" align-self-start">Inventori</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenInventori ? "rotate(0deg)" : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenInventori}>
              {navInventori.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item ml-4")}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-capitalize" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Components</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
