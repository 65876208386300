import React from 'react';
import ReactDOM from 'react-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import { Provider as ReduxProvider } from 'react-redux';

import { initialState, appReducers } from './reducers/';
import { AppStateProvider } from './contexts/appState';

import App from './App';
import configureStore from './redux/configureStore';

const store = configureStore();

ReactDOM.render(
  <ReduxProvider store={store}>
    <AppStateProvider reducer={appReducers} initialState={initialState}>
      <App />
    </AppStateProvider>
  </ReduxProvider>,
  document.getElementById('root'),
);
