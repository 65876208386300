import { GET_PELANGGAN, EDIT_PELANGGAN, UPDATE_PELANGGAN, ADD_PELANGGAN, REGPELANGGAN_SELECTED } from "../../pages/actions/pelangganAction";

const pelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case REGPELANGGAN_SELECTED:
      return {
        ...state,
        regpelangganselectedResult: action.payload.data,
      };
    case GET_PELANGGAN:
      return {
        ...state,
        getpelangganResult: action.payload.data,
        getpelangganLoading: action.payload.loading,
        getpelangganError: action.payload.errorMessage,
      };
    case ADD_PELANGGAN:
      return {
        ...state,
        addpelangganResult: action.payload.data,
        addpelangganLoading: action.payload.loading,
        addpelangganError: action.payload.errorMessage,
      };
    case EDIT_PELANGGAN:
      return {
        ...state,
        editpelangganResult: action.payload.data,
      };
    case UPDATE_PELANGGAN:
      return {
        ...state,
        updatepelangganResult: action.payload.data,
        updatepelangganLoading: action.payload.loading,
        updatepelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default pelanggan;
