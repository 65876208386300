import axios from 'axios';
import { API_URL_setuppelanggan } from '../constants';

export const SETUP_PELANGGAN = 'SETUP_PELANGGAN';

export const setupPelanggan = (dispatch, data) => {
  console.log(data);
  dispatch({
    type: SETUP_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'POST',
    url: API_URL_setuppelanggan,
    timeout: 120000,
    data: data,
  })
    .then(response => {
      dispatch({
        type: SETUP_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: SETUP_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
