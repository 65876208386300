import { REBOOT_MODEM } from '../../pages/actions/rebootmodemAction.js';

const rebootmodem = (state, action) => {
  const { type } = action;
  switch (type) {
    case REBOOT_MODEM:
      return {
        ...state,
        rebootmodemResult: action.payload.data,
        rebootmodemLoading: action.payload.loading,
        rebootmodemError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default rebootmodem;
