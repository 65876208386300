import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../api/authenticationApi";
import "./block.css";
import Logout from "./Logout";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  let token = isAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          token.level == "Teknisi" || token.level == "Sales" || token.level == "Admin" || token.level == "Direktur" || token.level == "Kasir" || token.level == "Noc"? (
            <Component {...props} />
          ) : (
            <div className="block">
              <div className="text">Maaf Hayanya Teknis Dan Sales</div>
              <Logout keluar="Keluar" />
            </div>
          )
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
