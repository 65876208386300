import {
  ADD_DATA_TEKNIS,
  GET_DATA_TEKNIS,
  UPDATE_DATA_TEKNIS,
} from '../../pages/actions/datateknisAction';

const datateknis = (state, action) => {
  const { type } = action;
  switch (type) {
    case ADD_DATA_TEKNIS:
      return {
        ...state,
        adddatateknisResult: action.payload.data,
        adddatateknisLoading: action.payload.loading,
        adddatateknisError: action.payload.errorMessage,
      };
    case GET_DATA_TEKNIS:
      return {
        ...state,
        getdatateknisResult: action.payload.data,
        getdatateknisLoading: action.payload.loading,
        getdatateknisError: action.payload.errorMessage,
      };
    case UPDATE_DATA_TEKNIS:
      return {
        ...state,
        updatedatateknisResult: action.payload.data,
        updatedatateknisLoading: action.payload.loading,
        updatedatateknisError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default datateknis;
