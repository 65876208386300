import {
  TEST_AKUN_ROUTER,
  // ADD_USER,
  // DELETE_USER,
  // EDIT_USER,
  // UPDATE_USER,
} from '../../pages/actions/tesakunRouterAction';

const tesakunrouter = (state, action) => {
  const { type } = action;
  switch (type) {
    case TEST_AKUN_ROUTER:
      return {
        ...state,
        tesakunrouterResult: action.payload.data,
        tesakunrouterLoading: action.payload.loading,
        tesakunrouterError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default tesakunrouter;
