import { LOGIN_USER_SUCCESS } from "../../actions/types";

const initialState = {
  loginuserResult: false,
  loginuserLoading: false,
  loginuserError: false,
};

const loginuser = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginuserResult: action.payload.data,
        loginuserLoading: action.payload.loading,
        loginuserError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default loginuser;
