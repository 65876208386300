import GAListener from "./components/GAListener";
import { MainLayout } from "./components/Layout";
import PageSpinner from "./components/PageSpinner";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./styles/reduction.scss";
import PrivateRoute from "./components/authentication/Privateroute";
import PublicRoute from "./components/authentication/PublicRoute";
import Login from "./components/authentication/Login";

const Listfttx = React.lazy(() => import("./pages/filemaster/listfttx/Listfttx"));
const Registrasifttx = React.lazy(() => import("./pages/registrasifttx/Registrasifttx.js"));
const Registrasi = React.lazy(() => import("./pages/pelanggan/registrasi/RegistrasiTab"));
const Configfttx = React.lazy(() => import("./pages/fttxconfigure/Configurefttx"));
const Datapelanggan = React.lazy(() => import("./pages/pelanggan/datapelanggan/Datapelanggan"));
const Tagihan = React.lazy(() => import("./pages/pelanggan/tagihan/Tagihan"));

const Tiketgangguan = React.lazy(() =>
  import('pages/pelanggan/tiketgangguan/GangguanTab'),
);

const Gangguanpelanggan = React.lazy(() => import("./pages/pelanggan/datapelanggan/Gangguanpelanggan"));
const Tspelanggan = React.lazy(() => import("./pages/pelanggan/datapelanggan/Tspelanggan"));
const Nodeolt = React.lazy(() => import("./pages/filemaster/nodeolt/Dataolt"));
const Wilayah = React.lazy(() => import("./pages/filemaster/wilayah/Datawilayah"));
const Users = React.lazy(() => import("./pages/users/Users"));

const TesModem = React.lazy(() => import('./pages/equipmentfttx/tesmodem/TesModemTab'));

const AlertPage = React.lazy(() => import("./pages/AlertPage"));
const BadgePage = React.lazy(() => import("./pages/BadgePage"));
const ButtonGroupPage = React.lazy(() => import("./pages/ButtonGroupPage"));
const ButtonPage = React.lazy(() => import("./pages/ButtonPage"));
const CardPage = React.lazy(() => import("./pages/CardPage"));
const ChartPage = React.lazy(() => import("./pages/ChartPage"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const DropdownPage = React.lazy(() => import("./pages/DropdownPage"));
const FormPage = React.lazy(() => import("./pages/FormPage"));
const InputGroupPage = React.lazy(() => import("./pages/InputGroupPage"));
const ModalPage = React.lazy(() => import("./pages/ModalPage"));
const ProgressPage = React.lazy(() => import("./pages/ProgressPage"));
const TablePage = React.lazy(() => import("./pages/TablePage"));
const TypographyPage = React.lazy(() => import("./pages/TypographyPage"));
const WidgetPage = React.lazy(() => import("./pages/WidgetPage"));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/listfttx" component={Listfttx} />
                <PrivateRoute exact path="/registrasifttx" component={Registrasifttx} />
                <PrivateRoute exact path="/registrasi" component={Registrasi} />
                <PrivateRoute exact path="/configfttx" component={Configfttx} />
                <PrivateRoute exact path="/datapelanggan" component={Datapelanggan} />
                <PrivateRoute
                  exact
                  path="/tiketgangguan"
                  component={Tiketgangguan}
                />
                <PrivateRoute exact path="/gangguanpelanggan" component={Gangguanpelanggan} />
                <PrivateRoute exact path="/tspelanggan" component={Tspelanggan} />
                <PrivateRoute exact path="/nodeolt" component={Nodeolt} />
                <PrivateRoute exact path="/wilayah" component={Wilayah} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/tesmodem" component={TesModem} />

                <Route exact path="/buttons" component={ButtonPage} />
                <Route exact path="/cards" component={CardPage} />
                <Route exact path="/widgets" component={WidgetPage} />
                <Route exact path="/typography" component={TypographyPage} />
                <Route exact path="/alerts" component={AlertPage} />
                <Route exact path="/tables" component={TablePage} />
                <Route exact path="/badges" component={BadgePage} />
                <Route exact path="/button-groups" component={ButtonGroupPage} />
                <Route exact path="/dropdowns" component={DropdownPage} />
                <Route exact path="/progress" component={ProgressPage} />
                <Route exact path="/modals" component={ModalPage} />
                <Route exact path="/forms" component={FormPage} />
                <Route exact path="/input-groups" component={InputGroupPage} />
                <Route exact path="/charts" component={ChartPage} />
                {/* <Route path="*" component={() => '404 NOTE FOUND'} /> */}
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
