import { SETUP_PELANGGAN } from '../../pages/actions/setuppelangganAction';

const setuppelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case SETUP_PELANGGAN:
      return {
        ...state,
        setuppelangganResult: action.payload.data,
        setuppelangganLoading: action.payload.loading,
        setuppelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default setuppelanggan;
