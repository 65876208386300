import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL_rebootmodem } from '../constants';

export const REBOOT_MODEM = 'REBOOT_MODEM';

export const rebootModemPelanggan = (dispatch, data) => {
  dispatch({
    type: REBOOT_MODEM,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  Swal.fire({
    title: 'Reboot Modem',
    text: 'Akses Internet akan terputus sesaat!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Batal',
    confirmButtonText: 'Reboot',
  }).then(result => {
    if (result.isConfirmed) {
      axios.post(API_URL_rebootmodem, data).then(response => {
        if (response.data.status == 201) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response.data.messages,
          });
        } else {
          Swal.fire('Reboot Selesai', 'Modem Berhail Direboot', 'success');
          dispatch({
            type: REBOOT_MODEM,
            payload: {
              loading: false,
              data: true,
              errorMessage: false,
            },
          });
        }
      });
    }
  });
};
