import {
  GET_OLT_REGPELANGGAN,
  ADD_OLT_REGPELANGGAN,
  DELETE_OLT_REGPELANGGAN,
  EDIT_OLT_REGPELANGGAN,
  UPDATE_OLT_REGPELANGGAN,
} from '../../pages/actions/oltregpelangganAction';

const oltregpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_OLT_REGPELANGGAN:
      return {
        ...state,
        getOltRegpelangganResult: action.payload.data,
        getOltRegpelangganLoading: action.payload.loading,
        getOltRegpelangganError: action.payload.errorMessage,
      };
    case ADD_OLT_REGPELANGGAN:
      return {
        ...state,
        addOltRegpelangganResult: action.payload.data,
        addOltRegpelangganLoading: action.payload.loading,
        addOltRegpelangganError: action.payload.errorMessage,
      };
    case DELETE_OLT_REGPELANGGAN:
      return {
        ...state,
        deleteOltRegpelangganResult: action.payload.data,
        deleteOltRegpelangganLoading: action.payload.loading,
        deleteOltRegpelangganError: action.payload.errorMessage,
      };
    case EDIT_OLT_REGPELANGGAN:
      return {
        ...state,
        editOltRegpelangganResult: action.payload.data,
      };
    case UPDATE_OLT_REGPELANGGAN:
      return {
        ...state,
        updateOltRegpelangganResult: action.payload.data,
        updateOltRegpelangganLoading: action.payload.loading,
        updateOltRegpelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default oltregpelanggan;
