import {
  GET_GANGGUAN,
  ADD_GANGGUAN,
  UPDATE_GANGGUAN,
  GET_TIKET_GANGGUAN,
  DELETE_GANGGUAN,
} from '../../pages/actions/gangguanAction';

const gangguanpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_GANGGUAN:
      return {
        ...state,
        getgangguanResult: action.payload.data,
        getgangguanLoading: action.payload.loading,
        getgangguanError: action.payload.errorMessage,
      };

    case GET_TIKET_GANGGUAN:
      return {
        ...state,
        gettiketgangguanResult: action.payload.data,
        gettiketgangguanLoading: action.payload.loading,
        gettiketgangguanError: action.payload.errorMessage,
      };
    case ADD_GANGGUAN:
      return {
        ...state,
        addgangguanResult: action.payload.data,
        addgangguanLoading: action.payload.loading,
        addgangguanError: action.payload.errorMessage,
      };
    case DELETE_GANGGUAN:
      return {
        ...state,
        deletegangguanResult: action.payload.data,
        deletegangguanLoading: action.payload.loading,
        deletegangguanError: action.payload.errorMessage,
      };
    case UPDATE_GANGGUAN:
      return {
        ...state,
        updategangguanResult: action.payload.data,
        updategangguanLoading: action.payload.loading,
        updategangguanError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default gangguanpelanggan;
