import {
  GET_TAGIHAN_PELANGGAN,
  EDIT_TAGIHAN_PELANGGAN,
  UPDATE_TAGIHAN_PELANGGAN,
} from '../../pages/actions/tagihanpelangganAction';

const tagihanpelanggan = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_TAGIHAN_PELANGGAN:
      return {
        ...state,
        getTagihanpelangganResult: action.payload.data,
        getTagihanpelangganLoading: action.payload.loading,
        getTagihanpelangganError: action.payload.errorMessage,
      };
    case EDIT_TAGIHAN_PELANGGAN:
      return {
        ...state,
        editTagihanpelangganResult: action.payload.data,
      };
    case UPDATE_TAGIHAN_PELANGGAN:
      return {
        ...state,
        updateTagihanpelangganResult: action.payload.data,
        updateTagihanpelangganLoading: action.payload.loading,
        updateTagihanpelangganError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default tagihanpelanggan;
