import axios from "axios";
import { database } from "faker/locale/en_CA";
import Swal from "sweetalert2";
import { API_URL_datapelanggan } from "../constants";
import { API_URL_createpelanggan } from "../constants";
import { API_URL_datapelanggandetail } from "../constants";

export const GET_PELANGGAN = "GET_PELANGGAN";
export const ADD_PELANGGAN = "ADD_PELANGGAN";
export const EDIT_PELANGGAN = "EDIT_PELANGGAN";
export const UPDATE_PELANGGAN = "UPDATE_PELANGGAN";
export const DELETE_PELANGGAN = "DELETE_PELANGGAN";
export const REGPELANGGAN_SELECTED = "REGPELANGGAN_SELECTED";

export const uploadDataPelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: 'PUT',
    url: API_URL_datapelanggandetail + data.pk,
    timeout: 120000,
    data: data.formData,
  })
    .then(response => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.messages,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Good job!',
          text: response.data.messages,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      });
      dispatch({
        type: UPDATE_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const RegpelangganSelected = (dispatch, data) => {
  dispatch({
    type: REGPELANGGAN_SELECTED,
    payload: {
      data: data,
    },
  });
};

export const getPelanggan = (dispatch, data) => {
  // const ofsite = data.limit * data.dataselected;
  // const param = '?limit=' + data.limit + '&offset=' + ofsite;
  // console.log(data);
  dispatch({
    type: GET_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "GET",
    url: API_URL_datapelanggan + data.jenispelanggan + "-" +data.tipepelanggan+"-"+ data.status + data.param,
  })
    .then((response) => {
      dispatch({
        type: GET_PELANGGAN,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const addPelanggan = (dispatch, data) => {
  dispatch({
    type: ADD_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "POST",
    url: API_URL_createpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: ADD_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: ADD_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};

export const editPelanggan = (dispatch, data) => {
  dispatch({
    type: EDIT_PELANGGAN,
    payload: {
      data: data,
    },
  });
};

export const updatePelanggan = (dispatch, data) => {
  dispatch({
    type: UPDATE_PELANGGAN,
    payload: {
      loading: true,
      data: false,
      errorMessage: false,
    },
  });

  axios({
    method: "PUT",
    url: API_URL_datapelanggandetail + data.idpelanggan,
    timeout: 120000,
    data: data,
  })
    .then((response) => {
      if (response.data.status == 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.messages,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.messages,
        });
        dispatch({
          type: UPDATE_PELANGGAN,
          payload: {
            loading: false,
            data: response.data,
            errorMessage: false,
          },
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
      dispatch({
        type: UPDATE_PELANGGAN,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    });
};
