import {
  GET_OLT,
  GET_OLT_DETAIL,
  ADD_OLT,
  DELETE_OLT,
  EDIT_OLT,
  UPDATE_OLT,
} from '../../pages/actions/nodeoltAction';

const nodeolt = (state, action) => {
  const { type } = action;
  switch (type) {
    case GET_OLT:
      return {
        ...state,
        getOltResult: action.payload.data,
        getOltLoading: action.payload.loading,
        getOltError: action.payload.errorMessage,
      };
    case GET_OLT_DETAIL:
      return {
        ...state,
        getOltDetailResult: action.payload.data,
        getOltDetailLoading: action.payload.loading,
        getOltDetailError: action.payload.errorMessage,
      };
    case ADD_OLT:
      return {
        ...state,
        addOltResult: action.payload.data,
        addOltLoading: action.payload.loading,
        addOltError: action.payload.errorMessage,
      };
    case DELETE_OLT:
      return {
        ...state,
        deleteOltResult: action.payload.data,
        deleteOltLoading: action.payload.loading,
        deleteOltError: action.payload.errorMessage,
      };
    case EDIT_OLT:
      return {
        ...state,
        editOltResult: action.payload.data,
      };
    case UPDATE_OLT:
      return {
        ...state,
        updateOltResult: action.payload.data,
        updateOltLoading: action.payload.loading,
        updateOltError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default nodeolt;
