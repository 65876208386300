// import axios from 'axios';
// import { API_URL_loginuser } from '../../pages/constants';

// export const LOGIN_USER = 'LOGIN_USER';

// export const loginUser = data => {
//   return dispatch => {
//     dispatch({
//       type: LOGIN_USER,
//       payload: {
//         loading: true,
//         data: false,
//         errorMessage: false,
//       },
//     });

//     axios({
//       method: 'POST',
//       url: API_URL_loginuser,
//       data: data,
//       timeout: 120000,
//     })
//       .then(response => {
//         sessionStorage.setItem('email', response.data.email);
//         sessionStorage.setItem('username', response.data.username);
//         dispatch({
//           type: LOGIN_USER,
//           payload: {
//             loading: false,
//             data: response.data,
//             errorMessage: false,
//           },
//         });
//         window.location.href = '/registrasi';
//       })
//       .catch(error => {
//         dispatch({
//           type: LOGIN_USER,
//           payload: {
//             loading: false,
//             data: false,
//             errorMessage: error.message,
//           },
//         });
//       });
//   };
// };

import { LOGIN_USER_SUCCESS, LOGOUT_USER } from "./types";
import { obtainToken, logout } from "../../components/api/authenticationApi";

export function loginUserSuccess(token) {
  return { type: LOGIN_USER_SUCCESS, token };
}

export function loginUser(username, password) {
  return async function (dispatch) {
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: {
        loading: true,
        data: false,
        errorMessage: false,
      },
    });

    try {
      const response = await obtainToken(username, password);
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: {
          loading: false,
          data: response.data,
          errorMessage: false,
        },
      });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: {
          loading: false,
          data: false,
          errorMessage: error.message,
        },
      });
    }
  };
}

export function logoutUserSuccess() {
  return { type: LOGOUT_USER };
}

export function logoutUser() {
  return async function (dispatch) {
    await logout();
    dispatch(logoutUserSuccess());
  };
}
