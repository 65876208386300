const tesmodem = (state, action) => {
  const { type } = action;
  switch (type) {
    case 'ADD_CONFIGMODEM':
      return {
        ...state,
        addConfigModemResult: action.payload.data,
        addConfigModemLoading: action.payload.loading,
        addConfigModemError: action.payload.errorMessage,
      };
    case 'DELETE_CONFIGMODEM':
      return {
        ...state,
        deleteConfigModemResult: action.payload.data,
        deleteConfigModemLoading: action.payload.loading,
        deleteConfigModemError: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default tesmodem;
